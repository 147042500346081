var render = function () {
  var _vm$item, _vm$item2, _vm$item3;
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm.visibleForFloor(_vm.item) && _vm.visibleForRole(_vm.item) && !(_vm.item.hideForProduction && _vm.isProduction || (_vm$item = _vm.item) !== null && _vm$item !== void 0 && _vm$item.hideAll) ? _c('li', {
    staticClass: "nav-item",
    class: {
      'active': _vm.isActive,
      'disabled': _vm.item.disabled || !_vm.canViewVerticalNavMenuLink(_vm.item)
    }
  }, [_c('b-link', _vm._b({
    staticClass: "d-flex align-items-center pr-0",
    on: {
      "click": function click(e) {
        return _vm.handleClickName(e, _vm.linkProps);
      }
    }
  }, 'b-link', _vm.linkProps, false), [_vm.item.image ? _c('img', {
    staticClass: "mr-50",
    attrs: {
      "src": _vm.item.image,
      "height": "22"
    }
  }) : (_vm$item2 = _vm.item) !== null && _vm$item2 !== void 0 && _vm$item2.customIcon ? _c('IAmIcon', {
    staticClass: "mr-50",
    attrs: {
      "icon": (_vm$item3 = _vm.item) === null || _vm$item3 === void 0 ? void 0 : _vm$item3.customIcon,
      "size": "18"
    }
  }) : _c('feather-icon', {
    staticClass: "mr-50",
    attrs: {
      "icon": _vm.item.icon || 'CircleIcon'
    }
  }), _c('div', {
    staticClass: "flex-1 d-flex-between gap-2"
  }, [!_vm.isVerticalMenuCollapsed || _vm.isMouseHovered || _vm.$store.state.verticalMenu.isShowText ? _c('span', {
    staticClass: "menu-title text-truncate"
  }, [_vm._v(" " + _vm._s(_vm.t(_vm.item.title)) + " " + _vm._s(_vm.getRouteDescription(_vm.item) === 'F1' ? _vm.mamaShortName : _vm.getRouteDescription(_vm.item)) + " ")]) : _vm._e(), (_vm.item.tag || _vm.item.hideForProduction) && (!_vm.isVerticalMenuCollapsed || _vm.isMouseHovered) ? _c('b-badge', {
    attrs: {
      "pill": "",
      "variant": _vm.item.tagVariant || (_vm.item.hideForProduction ? 'light-danger' : '') || 'primary'
    }
  }, [_vm._v(" " + _vm._s(_vm.item.tag || (_vm.item.hideForProduction ? 'DEV' : '')) + " ")]) : _vm._e()], 1)], 1)], 1) : _vm._e();
}
var staticRenderFns = []

export { render, staticRenderFns }